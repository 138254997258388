.on-drag::after {
    background-color: rgba(0, 0, 0, 0.5) !important;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 10;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
}