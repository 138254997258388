.modal-dialog {
    max-width: 800px;
}

strong {
    font-weight: bold;
}

.ql-editor {
    color: #8899aa; 
    font-size: 14px;
}

.ql-editor p { 
    font-size: 14px;
}

.createWithAI{
    margin-top: 2em;
    .icon {
        text-align: center;     
    }

}

//Cursor

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}