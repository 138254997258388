.input-file {
    background-color: #f5f5f5;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    color: #333;

    &:hover {
        background-color: #e5e5e5;
    }
}


.MuiPaper-root {
    width: 35% !important
}

.file-upload {
    width: 100%;
    height: 200px;
    border: 2px dashed #aaa;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    cursor: pointer;
}