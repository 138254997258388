.prompt-item {
    transition: all 0.3s ease-in-out;
}
 
.fade-out {
    animation: fade-out 500ms ease-in-out;
}

.fade-in {
    animation: fade-in 500ms ease-in-out;
}

.md-prompt {
    white-space: pre-wrap;
}


@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
