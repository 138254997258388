.input-file {
    background-color: #f5f5f5;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    color: #333;

    &:hover {
        background-color: #e5e5e5;
    }
}

.swal2-container {
    z-index: 999999
}

.MuiPaper-root {
    width: 35% !important
}