.promptEditor {
    height: 90%!important;
    
}

.promptEditor .ql-editor {
    color: #8898aa;
}

.editorContainer {
    height: 370px;
}

.card-footer button {
    margin-top: 0 !important;
    
}