.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: Arial, sans-serif;
}

.app-header {
    background-color: #075e54;
    color: white;
    padding: 1rem;
    text-align: center;
}

.app-main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
}

.send-btn {
    padding: 0.6rem 1rem;
}

.dragZone {
    position: absolute;
    width: 97%;
    height: 97%;
    background-color: #ECECEC;
    z-index: 900;
    padding: 4em;
}


.dragZone .internalDragZone {
    display: flex;
  justify-content: center;
  align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: initial;
    border: 6px dashed #888;
    border-radius: 1em;
    text-align: center;
    
}

.chat {
    width: 800px;
    background-color: white;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-container {
    flex: 1;
    padding: 1rem;
    overflow: auto;
}

.message {
    margin-bottom: 0.5rem;
    color: #3b3b3b;
}


.message p{
    font-size: 0.8rem;
}

.message_b {
    margin-bottom: 0.5rem;
    padding-left: 15rem;
}

.input-container {
    display: flex;
    padding: 1rem;
    border-top: 1px solid #ccc;
}

.input-container input {
    flex: 1;
    padding: 0.5rem;
    font-size: 1rem;
}

.input-container button {
    padding: 0.5rem 1rem;
    background-color: #075e54;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 0.5rem;
}



.card-body {
    overflow: auto;
    scroll-behavior: smooth;
}

.card-body::-webkit-scrollbar {
    background-color: transparent;
}

.card-body::-webkit-scrollbar-thumb {
    background-color: rgba(255,255,255,.1);
    border-radius: 2rem;
    cursor: pointer;
}

.chat-panel .card-header {
    padding-bottom: 4px;
}



.chat-panel  .form-group  {
 
    margin-top: -1em;
    margin-left: -1em;
    margin-bottom: 0;
    padding: 0;
}

.chat-panel .message {
    border-radius: 0.4375rem;
    border-top-left-radius: 0;
    position: relative;
    background-color: #f6f9fc;
    padding: 1rem 1.25rem 1rem 2.5rem;
}

.chat-panel .avatar {
    margin-bottom: -2em;
    margin-left: -1em;

}

.debugger-panel .table .tableHeader h5 {
    margin: 0;  
}
.debugger-panel .table .tableHeader {
    border-top: solid 1px #eee;
    padding: 8px;
    border-bottom: solid 1px #eee;
    line-height: 2em;
    text-align: left;
    margin-bottom: 1rem;
    color: #525f7f;
    background-color: #f6f9fc;
    color: #8898aa;
    align-items: center;
}

.debugger-panel .table .tableRow {
    padding: 8px;
    border-bottom: solid 1px #eee;
    line-height: 2em;
    text-align: left;
    margin-bottom: 1rem;
    color: #8898aa;
    align-items: center;
}



.input-file {
    background-color: #f5f5f5;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    color: #333;

    &:hover {
        background-color: #e5e5e5;
    }
}


.MuiPaper-root {
    width: 35% !important
}

.file-upload {
    width: 100%;
    height: 200px;
    border: 2px dashed #aaa;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    cursor: pointer;
    flex-direction:column;
}

.text-attach {
    color: blue;
}

.chat-panel .dropdown-menu {
    min-width: 1rem !important;
    padding: 4px;
}
.chat-panel .dropdown-item {
    width: auto;
}

.chat-panel .dropdown-menu .dropdown-item > i {
    margin-right: 0;
}

.chat-panel .btn:not(:last-child) {
    margin-right: 0;
}

.attachment-card {
    max-width: 20rem; 
}

.attachment-card .png,.attachment-card .jpg {
    padding: 0;
    text-align: center;
}
.attachment-card .pdf,.attachment-card .csv, .attachment-card .json {
    padding-top: 4em;
}
